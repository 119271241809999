/* ionic.theme.default.scss /////
 * Description : Fichier contenant les styles de modèles génériques applicables à plusieurs pages
*/

/*#region Changement de la CSS déjà écrite */

// Région toolbar
ion-title {
	font-size: 16px;
}

calao-database-sync-status-icon .in-toolbar {
	background: rgb(200, 200, 200);
	background: radial-gradient(
		circle,
		rgb(220, 218, 218) 0%,
		var(--ion-color-background) 50%,
		var(--ion-color-background) 80%
	);
}

// Bouton menu principal
.first-name {
	text-transform: lowercase;
	margin: 0;

	&::first-letter {
		text-transform: capitalize;
	}
}

ion-item {
	ng-component {
		width: 100%;
	}
}

ion-list-header.ios {
	ion-label {
		margin: auto;
		font-size: 16px;
		font-weight: 500;
	}
}

ion-note.synchro-label.ios {
	margin: auto;
	font-size: 16px;
	font-weight: 400;
}

.marg-container {
	margin-right: 10px;
	margin-left: 10px;
	width: auto;
}

.border-item-top {
	border-radius: 8px 8px 0 0;
}

.border-item-btm {
	border-radius: 0 0 4px 4px;
}

ion-list.marg-container {
	border-radius: 20px;
	box-shadow: var(--calao-shadow);
	padding: 0;
}

calao-conversation-list ion-list.marg-container {
	box-shadow: var(--calao-shadow);
}

ion-virtual-scroll.marg-container {
	border-radius: 20px;
	box-shadow: var(--calao-shadow);
	padding: 0;
}

.border-radius-bottom,
.border-radius-bottom
	calao-virtual-scroll
	.calao-virtual-scroll-wrapper
	cdk-virtual-scroll-viewport
	.cdk-virtual-scroll-content-wrapper {
	border-radius: 0 0 20px 20px !important;
}

cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
	border-radius: 20px;
}

idl-invoicing cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
	border-radius: 0;
}

.calao-virtual-scroll-wrapper {
	border-radius: 20px !important;
}

idl-traitement-prestations-modal .calao-virtual-scroll-wrapper {
	background: transparent;
	box-shadow: none;
	padding: 8px;

	.cdk-virtual-scroll-content-wrapper {
		padding-bottom: 8px;
	}
}

ion-virtual-scroll {
	box-shadow: var(--calao-shadow);
	width: auto !important;
	margin-right: 10px;
	margin-left: 10px;
	border-radius: 20px;
}

ion-slide {
	validate-actes ion-list {
		border-radius: 0;
	}
	ion-list {
		box-shadow: var(--calao-shadow);
		width: auto;
		margin: 10px 10px 0;
		border-radius: 20px;
	}
}

ion-list.list-md {
	padding-top: 0;
	padding-bottom: 0;
}

ion-slide contacts-list ion-list {
	box-shadow: none;
	margin: 0;
	border-radius: 0;
}

ion-slide .traitements-list {
	margin: 10px;
}

ion-slide contacts-list ion-list.list-md {
	margin: 0;
}

ion-slide idl-conversations-list ion-list {
	margin-top: 0;
}

ion-slide idl-conversations-list ion-list.list-md {
	margin-top: 0;
}

ion-slide transmissions-list ion-list {
	margin-top: 0;
}

ion-slide transmissions-list ion-list.list-md {
	margin-top: 0;
}

calao-contact {
	.pad-infos {
		margin: 20px 10px;
		padding-bottom: 24px !important;
		background: white;
		box-shadow: var(--calao-shadow);
		border-radius: 20px;
		padding: 10px;
		width: auto;
	}

	.width100pc.dis-flex.pad-infos ion-item {
		.groups-list-visu {
			box-shadow: none;
			border-radius: 0;
			border: none;
			padding: 2px 0;
			text-transform: capitalize;
			font-size: 14px;
			color: var(--ion-color-osapp-element);
		}

		.marg-infos {
			max-width: 100%;
		}

		ion-list-header ion-label.md {
			margin: auto;
		}

		ion-label.md {
			margin-top: -16px;
		}

		ion-label.no-groups {
			margin: auto;
			padding-top: 10px;
		}
	}

	.txt-infos {
		color: var(--ion-color-dark);
	}
}

ion-slide .width100pc.dis-flex.pad-infos {
	background: white;
	box-shadow: var(--calao-shadow);
	border-radius: 8px;
	margin: 10px;
	width: auto;
}

.sc-ion-searchbar-ios-h {
	--background: white;
}

.searchbar-input-container.sc-ion-searchbar-ios {
	box-shadow: var(--calao-shadow);
	border-radius: 8px;
	height: 42px;
}

.sc-ion-searchbar-md-h {
	--border-radius: 8px;
}

.header-list {
	ion-row {
		background: white;
		border-radius: 8px 8px 0 0;
	}

	ion-col {
		margin: auto;
		justify-content: center;
		display: flex;
	}

	ion-col:last-of-type {
		display: block;

		a {
			display: flex;

			ion-icon {
				vertical-align: middle;
				margin-right: 0.5rem;
				margin-left: auto;
			}
		}
	}

	.grid-content ion-col:last-of-type {
		text-align: left;
	}
}

ion-content {
	--background: var(--ion-color-background);
}

ion-menu ion-content {
	--background: white;
}

super-tabs-container ion-item {
	--background: transparent;
}

ion-virtual-scroll ion-item {
	--background: white;
}

ion-slide transmissions-list ion-item {
	--background: transparent;
}

ion-list ion-item {
	--background: white;
	background: white;
}

ion-slide {
	ion-list {
		background: transparent !important;
	}
}

ion-grid.table.table-striped .list {
	margin: 0;
	padding: 0;
	background: white;
	border-radius: 0 0 4px 4px;
	box-shadow: var(--calao-shadow);
}

.form-toolbar {
	margin: 10px 10px 10px;
	background: white;
	border-radius: 20px;
	box-shadow: var(--calao-shadow);
}

group-details form,
site-details form {
	margin: 10px 10px 10px;
	background: white;
	border-radius: 20px;
	box-shadow: var(--calao-shadow);
	padding-bottom: 50px;
	margin-bottom: 5rem;

	.img {
		background: transparent !important;
	}
}

form ion-item {
	--background: transparent;
}

.item-divider {
	background-color: white !important;
}

.marge-last {
	margin-bottom: 24px;
	display: block;
}

mat-expansion-panel {
	border-radius: 8px !important;
}

::-webkit-input-placeholder {
	font-style: italic;
}

:-moz-placeholder {
	font-style: italic;
}

::-moz-placeholder {
	font-style: italic;
}

:-ms-input-placeholder {
	font-style: italic;
}

.button-md {
	box-shadow: none !important;
}

.itemb {
	height: 4rem !important;
}

.txt-l {
	text-align: left !important;
}

.margelect {
	margin: 0 0.5rem 0 0;
}

.marge-nom {
	padding-left: 0.5rem;
}

.margegender {
	font-size: 15px;
	padding: 1px;
}

.margegender.ios {
	padding-left: 0;
}

.gender ion-item.pad-end {
	--inner-padding-end: 0;
	padding-bottom: 0;
}

.margegender ion-item span {
	font-size: 14px;
}

.padbtm-infos ion-item {
	padding-bottom: 0.2rem;
	--inner-padding-end: 2px !important;
}

.padbtm-infos span,
a {
	font-size: 12px;
}

.briefcase-txt span {
	font-size: 16px;
}

.padbtm-infos ion-icon {
	margin-top: 4px;
	margin-bottom: auto;
	margin-right: 0.5rem;
	font-size: 15px;
	color: var(--ion-color-medium);
	padding: 1px;
}

.wrap-infos ion-item span {
	white-space: nowrap !important;
	text-overflow: ellipsis;
	overflow: hidden;
	text-decoration: none;
}

.city-label span {
	white-space: nowrap !important;
	text-overflow: ellipsis;
	overflow: hidden;
}

.city formly-group {
	margin-left: 8px;
}

.ctn-patient-top formly-group .ctn-entete formly-group .ctn-infos formly-group .city formly-group {
	margin-left: 20px;
}

.contact-infos {
	ion-item {
		--inner-padding-end: 2px !important;

		.marg-infos {
			max-width: 100%;

			span {
				white-space: nowrap !important;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}

.capitalize {
	span {
		text-transform: lowercase;
	}

	span::first-letter {
		text-transform: capitalize;
	}
}

.marge-name ion-item .marg-infos span {
	font-family: Be Vietnam Bold;
	font-size: 16px;
}

.italic {
	font-style: italic;
}

.content-md {
	color: #535353;
}

.item-md {
	color: #424242 !important;
}

.swiper-container {
	position: absolute !important;
	width: 100%;
	min-height: 100%;
	margin-bottom: 5rem;
	height: auto !important;
}

.swiper-wrapper {
	height: auto !important;
	min-height: 100% !important;
}

.swiper-slide {
	display: block !important;
}

.btn-ctct {
	--border-radius: 2rem !important;
	height: 52px !important;
	width: 52px;
	--background: var(--ion-color-osapp-element);

	ion-icon {
		font-size: 28px !important;
	}
}

.btn-ctct + ion-note {
	color: var(--ion-color-osapp-element);
}

textarea {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid gray;
	color: gray;
}

textarea.mat-input-element.cdk-textarea-autosize {
	max-height: 75px !important;
	min-height: 40px !important;
	font-family: "Be Vietnam";
}

/*#endregion Changement de la CSS déjà écrite*/

.title-color {
	color: #1768de;
}

.padtopLink {
	padding-top: 0.5rem;
}

.padding-l {
	width: 100%;
	text-align: left;
}

.pad-infos {
	padding: 1rem 1rem 0 1rem;
}

.input[type="text"] {
	border: none;
	border-bottom: 1px solid #dedede;
}

.input[type="email"] {
	border: none;
	border-bottom: 1px solid #dedede;
}

.input[type="number"] {
	border: none;
	border-bottom: 1px solid #dedede;
}

.input::placeholder {
	font-style: italic;
}

.txt-col-info {
	color: #1768de;
}

.marge-r {
	margin-right: 1rem;
}

.marge-t {
	margin-top: 0.8rem;
}

.float-r {
	float: right;
	margin-right: 1rem;
}

.float-l {
	float: left;
	margin-right: 1rem;
}

.min-w {
	min-width: 7rem;
}

.over-date {
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.max-over-flow {
	max-width: 20rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.maxi-over-flow {
	max-width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.over-flow {
	width: 20rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.marge-name {
	font-weight: bold;
	margin-top: auto;
	margin-bottom: auto;
}

.margenter {
	text-align: center;
	margin-bottom: 0.3rem;
}

.roundavatar {
	border-radius: 50%;
	background: #87c4ff;
	overflow: hidden;
}

.round-etat {
	position: relative;
	border-radius: 50%;
	background: var(--ion-color-primary);
	overflow: hidden;
	width: 40px;
	height: 40px;

	ion-icon {
		color: var(--ion-color-tertiary-contrast);
		width: 60%;
		height: 60%;
		padding: 0.5rem;
	}
}

.marg-etat {
	margin: 4px 0;
}

.img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}

.img-rounded {
	border-radius: 50%;
	margin-top: 1rem !important;
	margin: 8px;
	width: 8rem !important;
	height: 8rem !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	background: var(--ion-color-tertiary);
	color: var(--ion-color-tertiary-contrast);
}

.img-thumbnail {
	object-fit: cover;
	min-width: 100%;
	max-height: 22rem;
}

.auto {
	object-fit: cover;
	max-width: none;
	min-height: 100%;
	height: 15rem;
}

.img-rounded ion-img {
	height: 100%;
}

.input {
	width: 98%;
	margin-left: 1%;
	margin-bottom: 0.3rem;
}

.margen {
	margin-bottom: 0.3rem;
	text-align: left;
}

.input-file {
	width: 3.6rem;
	height: 3.6rem;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.picture {
	display: flex;
	justify-content: center;
	max-width: 100%;
}

.picture-edit {
	border-radius: 20px 20px 0 0;
	position: relative;
	display: flex;
}

.avatar.auto.img-thumbnail {
	width: 100%;
	height: auto;
}

.wrapper .count {
	background-color: var(--ion-color-tertiary) !important;
	color: var(--ion-color-dark) !important;
	padding-top: 9px !important;
	padding-left: 21px !important;
	margin-left: -20px !important;
	font-size: 12px !important;
	font-weight: bold;
}

idl-invoicing calao-avatars .wrapper .count {
	background-color: lightgrey !important;
}

.marge-icon {
	display: flex;
	justify-content: center;
}

.center {
	width: 98%;
	text-align: center;
}

.txt-center {
	text-align: center;
}

.gradient {
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 85%);
}

.height50pc {
	height: 50%;
}

.add-participant {
	ion-icon {
		font-size: 24px !important;
	}
}

/*#region Boutons Globaux ------------------------------- */
.calao-btn {
	background-color: var(--ion-color-primary);
	color: #fff;
	border: 0;
	border-radius: 2px;
}

/*... Boutons icône */
.calao-btn.calao-btn-red {
	background-color: #fff;
	font-size: 1rem;
	color: #ff4040;
}

/*... Bouton transparent */
.calao-btn.calao-btn-transparent {
	background-color: transparent;
	height: 100%;
	border: 0;
	color: inherit;
}

.calao-btn.calao-btn-transparent:hover,
.calao-btn.calao-btn-transparent:focus,
.calao-btn.calao-btn-transparent:active {
	background-color: #eee;
}

/*... Bouton rouge hover */
.calao-btn.calao-btn-red-hover:hover,
.calao-btn.calao-btn-red-hover:focus,
.calao-btn.calao-btn-red-hover:active {
	color: #ff4040;
	background: none;
}

/*#endregion Boutons Globaux ------------------------------ */

/*#region Texte ------------------------------------------- */
.txt-label {
	font-size: var(--custom-size-12px);
	color: var(--custom-appcolors-light);
	min-width: 5rem;
}

/*#endregion Texte ---------------------------------------- */

/*#region Backgrounds ------------------------------------- */
/*... Background */
.bg-dark-green {
	background-color: #72ac3a;
}

.bg-purple {
	background-color: #a386df;
}

.bg-light-red {
	background-color: #df9a86;
}

.bg-orange {
	background-color: #e6b768;
}

.bg-sky-blue {
	background-color: #8cdbe1;
}

.bg-yellow {
	background-color: #e3ce41;
}

/*#endregion Backgrounds ---------------------------------- */

/*#region Tableau ----------------------------------------- */
.table.table-striped {
	tr:nth-of-type(even) {
		background: #fcfcfc;
	}

	tr:nth-of-type(odd) {
		background: #eaeaea;
	}

	td {
		padding: 0 10px;
	}
}

/*#endregion Tableau -------------------------------------- */

/*#region Message écran vide ------------------------------ */
.empty-screen {
	display: flex;
	flex: 1;
	padding-top: 3rem;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	font-size: var(--custom-size-16px);

	.icon {
		margin: 0 0.5rem 0 0;
	}

	.txt-message {
		padding: 0.5rem 0.75rem;
		border-radius: 3px;
		display: flex;
		color: var(--ion-color-medium);
		font-size: 14px;
		font-family: "Be Vietnam";
		align-items: center;

		ion-icon {
			margin-right: 8px;
		}
	}
}

mat-expansion-panel {
	transmissions-list {
		.empty-screen {
			padding: 0 !important;
		}
	}

	.marg-container {
		margin: 0;
	}
}

mat-expansion-panel {
	.surveillances-list,
	transmissions-list,
	.facturation-list {
		.empty-screen {
			padding: 0.5rem 0;
			border-radius: 3px;
			display: flex;
			color: var(--ion-color-medium);
			font-size: 14px;
			font-family: "Be Vietnam";
			align-items: start;
			justify-content: start;

			.txt-message {
				padding: 0.5rem 0;
			}
		}
	}
}

mat-expansion-panel .surveillance-area .surveillances-list {
	margin: 0;
}

/*#endregion Message écran vide --------------------------- */

/*#region Formulaires ------------------------------------- */
/*#region Input, Select ------------------------------------*/
input,
.input,
select {
	border: 1px solid #ccc;
	border-radius: 2px;
	text-align: left;
	height: 40px;
	padding: 0 1rem;
}

/*#endregion Input, Select ---------------------------------*/

/*#region CheckBox ---------------------------------------- */
.checkbox-icon {
	position: relative;
	width: 28px;
	height: 28px;
	display: block;
	border: 0;
	background: transparent;
	cursor: pointer;
	-webkit-appearance: none;
	padding: 2px;
}

input:checked + .checkbox-icon:before {
	border-width: 2px;
}

input:checked + .checkbox-icon:after {
	opacity: 1;
}

/*#endregion CheckBox ------------------------------------- */

.action-button-text {
	font-size: 10px;
	color: #505050;
	word-break: normal;
	margin: 0;
}

/*#endregion Formulaires ---------------------------------- */

.item {
	--min-height: 24px;
	--max-height: 24px;

	ion-label {
		margin-top: 0;
		margin-bottom: 0;
	}
}

ion-button {
	--ripple-color: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
}

ion-button.button-small.button-solid,
.password-forgotten + .centera ion-button,
.password-input + .centera ion-button {
	--padding-top: 2px;
	--padding-bottom: 5px;

	ion-icon {
		margin-top: 3px;
	}
}

ion-item-options ion-button {
	--padding-start: 16px;
	--padding-end: 16px;
}

ion-button.ios.btn-more {
	--padding-end: 0;
}

.no-padding {
	padding: 0;
}

formly-field.align-action-btn readonly-wrapper ng-component {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modal-acte-manager-validate-alert .alert-head {
	padding: 0;
}

.width100pc formly-group formly-field .button-has-icon-only,
calao-groups-checklist .button-small,
ion-list .button-solid,
.file-picker-button,
.licamera-button,
.add-btn {
	--background: var(--ion-color-osapp-element) !important;
	--border-radius: 50px;
	font-family: Be Vietnam Bold;
}

.width100pc formly-group formly-field div .button-has-icon-only.button-solid,
.file-picker-button,
.licamera-button {
	height: 50px;
	width: 50px;
	--padding-start: 0 !important;
	--padding-end: 0 !important;
}

/* Alerte encaissement patient */
.invoice-popup {
	.alert-wrapper {
		background: var(--ion-color-background);
		border-radius: 20px;

		.alert-head h2 {
			color: var(--ion-color-primary);
			font-weight: bold;
		}

		.alert-message {
			font-size: 14px;
			padding-bottom: 8px;
		}

		.alert-radio-group {
			border: none;
			margin: 0 10px;
			box-shadow: var(--calao-shadow);

			button {
				background: white;
				border-bottom: 1px solid var(--ion-color-light-shade);
				height: 49px;

				.alert-radio-label {
					font-size: 14px;
				}
			}
		}

		.cancel-btn {
			background: var(--ion-color-light);
			color: var(--ion-color-danger);
			border-radius: 20px;
			padding: 10px 16px;
		}

		.validate-btn {
			background: var(--ion-color-success);
			color: white;
			border-radius: 20px;
			padding: 10px 16px;
			box-shadow: var(--calao-shadow);
		}
	}
}

/* Couleurs des entités */
calao-contact .picture-edit {
	background: var(--ion-color-contact-icon) !important;
}

group-details .picture-edit {
	background: var(--ion-color-group-icon) !important;
}

site-details .picture-edit {
	background: var(--ion-color-site-icon) !important;
}

idl-patient-form,
calao-contact,
#contact-form {
	.form-toolbar {
		background: none;
		border-radius: 0;
		box-shadow: none;
	}

	.img-area {
		margin: 8px 0;
		background: white;
		border-radius: 20px;
		box-shadow: var(--calao-shadow);
	}

	.bloc-area {
		margin: 8px 0;
		padding: 12px 8px;
		background: white;
		border-radius: 20px;
		box-shadow: var(--calao-shadow);

		.section-title {
			ion-label {
				color: var(--ion-color-sections-container) !important;
				font-size: 22px !important;
				padding-left: 10px;
				margin-top: -6px !important;
				margin-bottom: 14px !important;
			}
		}

		.section-title.tags-area {
			ion-label {
				margin-bottom: 22px !important;
			}
		}

		.mat-form-field-appearance-outline {
			margin: 2px 0;

			.mat-form-field-infix {
				padding: 2px 0 4px 0;
			}
		}

		.chips-ctn {
			margin: 0 10px;
		}

		.cy-patient-gender {
			.mat-form-field-wrapper {
				padding: 2px 0;
			}

			.mat-chip-list-wrapper {
				justify-content: center;
			}
		}

		.zipcode-area {
			.mat-form-field-appearance-outline {
				margin-right: 4px;
			}
		}

		.factu-key {
			font-size: 16px;
			color: var(--ion-color-medium);
			padding-top: 2px;
			font-family: "Be Vietnam";
		}
	}

	.mat-form-field-appearance-legacy {
		.mat-form-field-infix {
			margin: 0;
			padding: 0;
		}
	}

	.no-border .mat-form-field-appearance-legacy .mat-form-field-underline {
		display: none;
	}

	.gallery {
		ion-label {
			color: var(--ion-color-medium) !important;
			font-size: 12px !important;
			white-space: inherit !important;
		}
	}

	.tags {
		.mat-form-field-infix {
			padding-top: 14px;
		}
	}
}

idl-patient-form .picture-edit {
	background: var(--ion-color-patient-icon) !important;
}

idl-couverture-modal,
idl-facturation-context-modal {
	.sc-ion-modal-md-h {
		--border-radius: 20px;
	}

	.mat-form-field-appearance-outline {
		margin: 2px 0;
		width: 100%;
		max-width: 320px;

		.mat-form-field-infix {
			padding: unset;
		}

		.mat-form-field-wrapper {
			padding-bottom: 0;
		}
	}

	input.mat-input-element {
		height: 40px;
	}

	.mat-select-arrow {
		margin-top: 8px;
	}
}

// Classe utilisé pour décaler la toolbar et qu'elle ne bloque pas la cliquabilité de certains éléments de l'UI.
.push-toolbar {
	margin-bottom: 1.5rem;
}

.pad-edit-contacts {
	padding-left: 0.5rem;
}

.margin-group {
	margin-left: 0.5rem;
	padding-bottom: 0.2rem;
}

.margin-left {
	margin-left: 0.5rem;
	padding-top: 0.6rem;
}

/* Transmissions patients */
ion-slide transmissions-list ion-virtual-scroll ion-item ion-text.text-container {
	padding-left: 0.8rem;
}

ion-slide
	ng-component
	section.list.listSeances.marge-btm-list-seances
	mat-accordion
	mat-expansion-panel
	transmissions-list
	ion-virtual-scroll
	ion-item
	ion-text.text-container {
	padding-left: 0;
}

/* Bandeau des conversations */

@media screen and (max-width: 320px) {
	#frame .content .contact-profile .conv-infos {
		min-width: 50%;
	}

	#frame .content .contact-profile .element-lie {
		width: 50%;
	}
}

/* Marqueur item actif */

.actif {
	height: 10px;
	display: flex;
	align-items: center;
}

ion-item .actif {
	width: 100%;
}

// Marqueur conflit item
.background-warning {
	background-color: var(--ion-color-warning);
}

// Marqueur information item
.background-info {
	background-color: var(--ion-color-osapp-element);
}

/* Région de la super-tabs-toolbar */

super-tab-button {
	display: flex;
	flex-direction: row;
	justify-content: center;

	ion-icon {
		padding-top: 0;
		fill: var(--ion-color-primary);
	}

	ion-label {
		color: var(--ion-color-primary);
		display: flex;
		align-items: center;
		line-height: 16px;
		font-family: Be Vietnam Bold;
		text-transform: capitalize;
	}
}

super-tabs-toolbar {
	&:after {
		background: transparent;
	}
}

super-tab-button.ion-activatable {
	color: transparent !important;
}

super-tab-button.active {
	box-shadow: var(--idl-slidebox-shadow-active);
	z-index: 1;
}

super-tabs-toolbar {
	height: 3.4rem;
	background: transparent;
	--st-indicator-color: transparent;
	--st-indicator-height: 6px;

	super-tab-button {
		height: 3rem;
		background: var(--ion-color-background) !important;
		box-shadow: var(--idl-slidebox-shadow);
		border-radius: 20px 20px 0 0;
		margin-top: 10px;
	}
}

.slidebox-nav {
	background-color: transparent !important;
	padding-top: 10px;
	margin-bottom: -1px;
}

.slidebox-nav::-webkit-scrollbar {
	display: none;
}

// Onglet fiche patient et traitement
readonly-wrapper ng-component calao-slidebox .slidebox .slidebox-nav,
.title + calao-slidebox .slidebox .slidebox-nav {
	padding-top: 20px;

	.ion-color-slidebox-button {
		--ion-color-base: white !important;
		--ion-color-contrast: var(--ion-color-osapp-element);
		--border-radius: 20px !important;
		--box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
		height: 40px;
		margin-bottom: 5px;
	}

	.ion-color-slidebox-button.slidebox-active-tab {
		--border-radius: 20px 20px 0 0 !important;
		--ion-color-base: var(--ion-color-sections-container) !important;
		--ion-color-contrast: white;
		height: 45px;
		margin-bottom: 0;
	}
}

@media screen and (min-width: 780px) {
	.slidebox-nav {
		overflow-x: hidden !important;
	}
}

readonly-wrapper ng-component calao-slidebox .slidebox .slidebox-nav + ion-slides,
.title + calao-slidebox .slidebox ion-slides {
	background: var(--ion-color-sections-container);
}

.border-l.slidebox-active-tab {
	z-index: 1;
}

.border-l.slidebox-active-tab {
	border-bottom: none !important;
}

.slider-button .button-native {
	background-color: var(--ion-color-background) !important;
}

super-tabs-container {
	max-width: 100%;
	max-height: 100%;

	super-tab {
		max-width: 100%;
		max-height: 100%;

		ion-content {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

idl-facturation ion-toolbar {
	margin-bottom: 0 !important;
}

ion-toolbar.navbar {
	.sc-ion-buttons-md-h {
		margin-top: -0.5rem;
	}

	ion-title.md {
		margin-top: -0.5rem;
	}

	ion-buttons.ios {
		margin-top: 2px;
		margin-bottom: 2px;
	}

	ion-buttons ion-button {
		height: 36px !important;
		width: 36px !important;
	}

	.sc-ion-buttons-md-s .button-has-icon-only.button-clear {
		--padding-start: 5px;
		--padding-end: 5px;
	}
}

ion-toolbar,
.links ion-icon,
.links ion-icon + ion-note {
	color: var(--ion-color-primary) !important;
}

.header-md::after {
	display: none;
}

.header-ios ion-toolbar:last-of-type {
	--border-width: 0 0 0px !important;
}

indemnites cdk-virtual-scroll-viewport::-webkit-scrollbar {
	width: 0;
}

.seance-price avatar img {
	width: 28px !important;
	min-width: 28px !important;
	height: 28px !important;
}

/* Optimisations browser */

@media screen and (min-width: 600px) {
	/* Accueil */
	.marg-tuiles {
		margin: 0 6%;
	}
}

@media screen and (min-width: 992px) {
	/* Accueil */
	.marg-tuiles {
		margin: 0 12%;
	}

	/* Listes */
	transmissions-list .transmissions-list {
		padding: 0;
	}

	.transmissions-list {
		padding: 0 12%;
	}

	super-tab ion-content {
		--padding-start: 12%;
		--padding-end: 12%;
	}

	ion-slide {
		padding: 0 12%;
	}

	/* Slides traitement */
	.ctnEnteteDate {
		margin-top: 10px;
	}

	/* Patients et contacts */
	.form-toolbar {
		margin: 10px 20% 5rem !important;
		padding: 0;
	}

	calao-contact calao-form form {
		margin: 10px 26% !important;
		padding: 10px 20px;

		.marg-infos {
			max-width: 100%;
		}

		.ctn-entete {
			width: 100%;
		}

		.width100pc.dis-flex.pad-infos ion-item {
			box-shadow: none;
		}
	}

	.ctn-entete {
		width: 60%;
		margin: auto;

		.ctn-infos.pos-rel.dis-line.height100pc.width50pc {
			margin: 10px 20px;
		}
	}

	.ctn-infos.pos-rel.dis-line.height100pc.width50pc {
		border-radius: 8px;
		box-shadow: var(--calao-shadow);
		padding: 10px;
	}

	.row-bottom {
		padding-bottom: 1rem;
	}

	calao-contact .row-bottom {
		padding-bottom: 0;
	}

	.header-transmission {
		padding: 0 12%;
	}

	/* Conversations */
	.marg-browser {
		padding: 0 12%;
	}

	ion-slide idl-conversations-list .marg-browser {
		padding: 0;
	}
}

@media screen and (min-width: 760px) {
	ion-modal::part(content) {
		width: 80% !important;
		height: 80% !important;
		--border-radius: 20px;
	}
}

/* Hauteur du cdk virtual scroll */
contact-selector .selector-list cdk-virtual-scroll-viewport {
	max-height: 40vh;
}

@media (max-width: 759px) and (min-height: 660px) {
	contact-selector .selector-list cdk-virtual-scroll-viewport {
		max-height: 50vh;
	}
}

@media (max-width: 759px) and (min-height: 800px) {
	contact-selector .selector-list cdk-virtual-scroll-viewport {
		max-height: 55vh;
	}
}

/* CSS pour le mode browser du plugin cordova-plugin-camera */

.cordova-camera-capture {
	display: inline-grid;
	width: 100%;

	video {
		width: fit-content;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}

	button {
		margin-left: 40%;
		margin-right: 40%;
		background: var(--ion-color-primary, #3880ff);
		color: var(--ion-color-primary-contrast, #fff);
		height: 48px;
		border-radius: 8px;
		font-size: 16px;
	}
}

/* CSS types groupes */
.group-type .mat-chip-list-wrapper {
	margin: 0.5rem 0.2rem 1rem !important;
}

calao-groups-checklist {
	.divider {
		background-color: var(--ion-color-sections-container);
		height: 1px;
		flex-grow: 1;
		margin-left: 10px;
		margin-top: 6px;
		width: 76%;
	}
}

/* Affichage sélecteur dans les tournées */
.border-filter osapp-selector {
	drag-scroll {
		margin-top: 4px;
		height: 45px;
	}

	.left-button {
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.948327850877193) 35%,
			rgba(255, 255, 255, 1) 70%
		);
		left: 0;
		color: var(--ion-color-osapp-element);
	}

	.right-button {
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.948327850877193) 35%,
			rgba(255, 255, 255, 1) 70%
		);
		right: 0;
		color: var(--ion-color-osapp-element);
	}
}

/* Page de diagnostics */
calao-diagnostic > ion-list {
	ion-item {
		--min-height: 42px !important;
		--max-height: 42px !important;
	}
}

/* Hauteur du cdk virtual scroll */
cdk-virtual-scroll-viewport {
	max-height: 60vh;
	background-color: white;

	ion-item-sliding {
		ion-item {
			--min-height: 70px !important;
			--max-height: 70px !important;
			height: 70px;
			background-color: white;
		}
	}
}

idl-traitement-prestations-modal,
idl-rsp-waiting-list,
idl-rsp-archived-list {
	cdk-virtual-scroll-viewport {
		max-height: 82vh;
		@media screen and (min-width: 760px) {
			max-height: 60vh;
		}
	}
}

calao-conversation-list cdk-virtual-scroll-viewport {
	border-radius: 20px;
}

transmissions-list cdk-virtual-scroll-viewport ion-item-sliding ion-item {
	background-color: white;
}

@media (max-height: 640px) {
	cdk-virtual-scroll-viewport {
		max-height: 50vh;
	}
}

contact-selector {
	.empty-container {
		margin-top: 0 !important;
	}
	.marg-container {
		margin: 0 !important;
	}

	.selector-list {
		background: white;
		border-radius: 20px;
		margin-right: 10px;
		margin-left: 10px;
		margin-bottom: 5rem;
		padding: 14px 0px;
		box-shadow: var(--calao-shadow);
	}

	ion-item.user-contact,
	ion-virtual-scroll {
		margin: 0 !important;
		border-radius: 0px !important;
		box-shadow: none !important;
	}

	cdk-virtual-scroll-viewport {
		box-shadow: none !important;
	}
}

/* Hauteur du cdk virtual scroll si la zone de filtrage est ouverte */
cdk-virtual-scroll-viewport.open-filter {
	max-height: 30vh;
}

@media (max-height: 800px) {
	cdk-virtual-scroll-viewport.open-filter {
		max-height: 20vh;
	}
}

@media (max-height: 640px) {
	cdk-virtual-scroll-viewport.open-filter {
		max-height: 20vh;
	}
}

/* Optimisations side menu */
ion-toolbar.toolbar-menu {
	--min-height: var(--calao-toolbar-height, 48px);
	--max-height: var(--calao-toolbar-height, 48px);

	ion-button {
		pointer-events: none;
	}

	ion-icon {
		font-size: 34px;
	}

	ion-title {
		padding-left: 4px;
		font-weight: bold;
	}
}

.side-menu-with-header,
.side-menu-without-header {
	height: 100%;
}

.user-part {
	z-index: 10;

	ion-label {
		text-transform: none !important;
	}

	.standard-link {
		--background: var(--ion-color-sections-container-tint);
		background: var(--ion-color-sections-container-tint);
	}
}

.menu-part calao-link ion-item {
	--min-height: 32px;
	padding-top: 0;
}

.user-part calao-link ion-item {
	--min-height: 42px;
	padding-top: 0;
}

@media screen and (min-width: 992px) {
	ion-menu {
		--max-width: 280px;
		--min-width: 280px;
		--width: 280px;
	}
}

/* Affichage tag états et date des séances **/
.patient-tag {
	ion-icon {
		font-size: 14px;
	}
	avatar {
		ion-icon.avatar-icon {
			font-size: 14px !important;
			width: 14px !important;
			height: 14px !important;
			padding: 4px 4px 4px 8px;
		}
	}
}

/* Affichage des miniatures de la galerie d'ordonnances **/
mat-expansion-panel idl-ordonnances-gallery .gallery-content {
	width: 60px;
	height: 60px;

	.file-icon {
		font-size: 60px;
		color: gray;
	}

	.ctn-apercu {
		max-height: 60px;
		max-width: 60px;
	}
}

/* Optimisations side menu petit device */
@media screen and (max-width: 360px) {
	.user-part {
		ion-item-divider {
			height: 48px !important;

			ion-avatar {
				width: 30px !important;
				height: 30px !important;
			}
		}
	}
}

.line,
.divider {
	background-color: var(--ion-color-sections-container);
	height: 1px;
	flex-grow: 1;
	margin-left: 10px;
}
