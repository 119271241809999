// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/
$font-path: "./../assets/fonts";

@import "ionic.globals";

// Ionic Colors
// --------------------------------------------------

:root {
	/** Theme de base a modifier en priorité.
	* Pour generer un theme pour l'application :
	* https://ionicframework.com/docs/theming/color-generator
	*/

	--ion-color-primary: #1768de;
	--ion-color-primary-rgb: 23, 104, 222;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #145cc3;
	--ion-color-primary-tint: #2e77e1;

	--ion-color-secondary: var(--ion-color-primary);
	--ion-color-secondary-rgb: 23, 104, 222;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: var(--ion-color-primary-shade);
	--ion-color-secondary-tint: var(--ion-color-primary-tint);

	--ion-color-tertiary: #87c4ff;
	--ion-color-tertiary-rgb: 135, 196, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #77ace0;
	--ion-color-tertiary-tint: #f0f8ff;

	--ion-color-success: #18d495;
	--ion-color-success-rgb: 24, 212, 149;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #15bb83;
	--ion-color-success-tint: #2fd8a0;

	--ion-color-warning: #f9a637;
	--ion-color-warning-rgb: 249, 166, 55;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #db9230;
	--ion-color-warning-tint: #faaf4b;

	--ion-color-danger: #e94d53;
	--ion-color-danger-rgb: 233, 77, 83;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 0, 0, 0;
	--ion-color-danger-shade: #cd4449;
	--ion-color-danger-tint: #eb5f64;

	--ion-color-dark: #47494a;
	--ion-color-dark-rgb: 71, 73, 74;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #3e4041;
	--ion-color-dark-tint: #595b5c;

	--ion-color-medium: #6d7077;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #606369;
	--ion-color-medium-tint: #7c7e85;

	--ion-color-light: #f4f4f4;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d7d7;
	--ion-color-light-tint: #f5f5f5;

	--ion-color-osapp-element: #57b4fd;
	--ion-color-osapp-element-rgb: 87, 180, 253;
	--ion-color-osapp-element-contrast: #ffffff;
	--ion-color-osapp-element-contrast-rgb: 255, 255, 255;
	--ion-color-osapp-element-shade: #4d9edf;
	--ion-color-osapp-element-tint: #68bcfd;

	--ion-color-tile-icon: #ffffff;
	--ion-color-tile-icon-rgb: 255, 255, 255;
	--ion-color-tile-icon-contrast: #000000;
	--ion-color-tile-icon-contrast-rgb: 0, 0, 0;
	--ion-color-tile-icon-shade: #e0e0e0;
	--ion-color-tile-icon-tint: #ffffff;

	--ion-color-login: #4a94ff;
	--ion-color-login-rgb: 0, 102, 204;
	--ion-color-login-contrast: #ffffff;
	--ion-color-login-contrast-rgb: 255, 255, 255;
	--ion-color-login-shade: #1768de;
	--ion-color-login-tint: #87c4ff;

	--ion-color-sections-container: #a6e4fa;
	--ion-color-sections-container-rgb: 166, 228, 250;
	--ion-color-sections-container-contrast: #000000;
	--ion-color-sections-container-contrast-rgb: 0, 0, 0;
	--ion-color-sections-container-shade: #92c9dc;
	--ion-color-sections-container-tint: #afe7fb;

	--ion-color-purple: #a386df;
	--ion-color-purple-rgb: 163, 134, 223;
	--ion-color-purple-contrast: #000000;
	--ion-color-purple-contrast-rgb: 0, 0, 0;
	--ion-color-purple-shade: #8f76c4;
	--ion-color-purple-tint: #ac92e2;

	--ion-color-button: #57b4fd;

	--ion-color-patient-icon: #c2e06f;

	--ion-color-contact-icon: #df9a86;

	--ion-color-site-icon: #db8ee7;

	--ion-color-group-icon: #e6b768;

	--ion-color-slidebox-active-button: var(--ion-color-background);

	--ion-color-background: #f2f9fe;

	--calao-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.05);

	--idl-slidebox-shadow: 0px -1px 6px -2px rgba(0, 0, 0, 0.2), inset 0px -20px 30px -30px rgba(0, 0, 0, 0.15);

	--idl-slidebox-shadow-active: 0px -1px 6px -2px rgba(0, 0, 0, 0.2);

	--ion-font-family: "Be Vietnam Bold";
	--ion-font-family: "Be Vietnam Light";
	--ion-font-family: "Be Vietnam";
}

// --ion-color-osapp-element: Utilisable uniquement en tant que variable.
// .ion-color-osapp-element: Utilisable dans color="osapp-element".
.ion-color-osapp-element {
	--ion-color-base: var(--ion-color-osapp-element);
	--ion-color-base-rgb: var(--ion-color-osapp-element-rgb);
	--ion-color-contrast: var(--ion-color-osapp-element-contrast);
	--ion-color-contrast-rgb: var(--ion-color-osapp-element-contrast-rgb);
	--ion-color-shade: var(--ion-color-osapp-element-shade);
	--ion-color-tint: var(--ion-color-osapp-element-tint);
}

// --ion-color-fab-button: Utilisable uniquement en tant que variable.
// .ion-color-fab-button: Utilisable dans color="fab-button".
.ion-color-fab-button {
	--ion-color-base: var(--ion-color-osapp-element);
	--ion-color-base-rgb: var(--ion-color-osapp-element-rgb);
	--ion-color-contrast: var(--ion-color-osapp-element-contrast);
	--ion-color-contrast-rgb: var(--ion-color-osapp-element-contrast-rgb);
	--ion-color-shade: var(--ion-color-osapp-element-shade);
	--ion-color-tint: var(--ion-color-osapp-element-tint);
}

// --ion-color-button: Utilisable uniquement en tant que variable.
// .ion-color-button: Utilisable dans color="button".
.ion-color-button {
	--ion-color-base: var(--ion-color-osapp-element);
	--ion-color-base-rgb: var(--ion-color-osapp-element-rgb);
	--ion-color-contrast: var(--ion-color-osapp-element-contrast);
	--ion-color-contrast-rgb: var(--ion-color-osapp-element-contrast-rgb);
	--ion-color-shade: var(--ion-color-osapp-element-shade);
	--ion-color-tint: var(--ion-color-osapp-element-tint);
}

// --ion-color-login: Utilisable uniquement en tant que variable.
// .ion-color-login: Utilisable dans color="login".
.ion-color-login {
	--ion-color-login: #4286f4;
	--ion-color-login-rgb: 0, 102, 204;
	--ion-color-login-contrast: #ffffff;
	--ion-color-login-contrast-rgb: 255, 255, 255;
	--ion-color-login-shade: #4182e0;
	--ion-color-login-tint: #87c4ff;
}

// --ion-color-validation: Utilisable uniquement en tant que variable.
// .ion-color-validation: Utilisable dans color="validation".
.ion-color-validation {
	--ion-color-validation-rgb: var(--ion-color-success);
	--ion-color-validation-contrast: var(--ion-color-success-contrast);
	--ion-color-validation-contrast-rgb: var(--ion-color-success-contrast-rgb);
	--ion-color-validation-shade: var(--ion-color-success-shade);
	--ion-color-validation-tint: var(--ion-color-success-tint);
}

// --ion-color-slidebox-active-button: Utilisable uniquement en tant que variable.
.ion-color-slidebox-active-button {
	--ion-color-slidebox-active-button: var(--ion-color-background);
}

// --ion-color-background: Utilisable uniquement en tant que variable.
.ion-color-background {
	--ion-color-background: #f2f9fe;
}

/** Couleur de la toolbar */
.ion-color-toolbar {
	--ion-color-base: var(--ion-color-toolbar, var(--ion-color-background));
	--ion-color-base-rgb: var(--ion-color-toolbar-rgb, var(--ion-color-background-rgb));
	--ion-color-contrast: var(--ion-color-toolbar-contrast, var(--ion-color-background-contrast));
	--ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb, var(--ion-color-background-contrast-rgb));
	--ion-color-shade: var(--ion-color-toolbar-shade, var(--ion-color-background-shade));
	--ion-color-tint: var(--ion-color-toolbar-tint, var(--ion-color-background-tint));
}

/** Couleur des tuiles du menu*/
.ion-color-tile {
	--ion-color-base: var(--ion-color-tile, var(--ion-color-primary));
	--ion-color-base-rgb: var(--ion-color-tile-rgb, var(--ion-color-primary-rgb));
	--ion-color-contrast: var(--ion-color-tile-contrast, var(--ion-color-primary-contrast));
	--ion-color-contrast-rgb: var(--ion-color-tile-contrast-rgb, var(--ion-color-primary-contrast-rgb));
	--ion-color-shade: var(--ion-color-tile-shade, var(--ion-color-primary-shade));
	--ion-color-tint: var(--ion-color-tile-tint, var(--ion-color-primary-tint));
}

/** Couleur des icons dans les tuiles */
.ion-color-tile-icon {
	--ion-color-base: var(--ion-color-tile-icon, var(--ion-color-primary));
	--ion-color-base-rgb: var(--ion-color-tile-icon-rgb, var(--ion-color-primary-rgb));
	--ion-color-contrast: var(--ion-color-tile-icon-contrast, var(--ion-color-primary-contrast));
	--ion-color-contrast-rgb: var(--ion-color-tile-icon-contrast-rgb, var(--ion-color-primary-contrast-rgb));
	--ion-color-shade: var(--ion-color-tile-icon-shade, var(--ion-color-primary-shade));
	--ion-color-tint: var(--ion-color-tile-icon-tint, var(--ion-color-primary-tint));
}

/** Couleur des action button */
.ion-color-action-button {
	--ion-color-base: var(--ion-color-action-button, var(--ion-color-osapp-element));
	--ion-color-base-rgb: var(--ion-color-action-button-rgb, var(--ion-color-osapp-element-rgb));
	--ion-color-contrast: var(--ion-color-action-button-contrast, var(--ion-color-osapp-element-contrast));
	--ion-color-contrast-rgb: var(--ion-color-action-button-contrast-rgb, var(--ion-color-osapp-element-contrast-rgb));
	--ion-color-shade: var(--ion-color-action-button-shade, var(--ion-color-osapp-element-shade));
	--ion-color-tint: var(--ion-color-action-button-tint, var(--ion-color-osapp-element-tint));
}

/** Couleur des slidebox buttons */
.ion-color-slidebox-button {
	--ion-color-base: var(--ion-color-slidebox-button, var(--ion-color-background));
	--ion-color-base-rgb: var(--ion-color-slidebox-button-rgb, var(--ion-color-background));
	--ion-color-contrast: var(--ion-color-slidebox-button-contrast, var(--ion-color-primary));
	--ion-color-contrast-rgb: var(--ion-color-slidebox-button-contrast-rgb, var(--ion-color-background));
	--ion-color-shade: var(--ion-color-slidebox-button-shade, var(--ion-color-background));
	--ion-color-tint: var(--ion-color-slidebox-button-tint, var(--ion-color-background));
	--box-shadow: var(--idl-slidebox-shadow);
	--border-radius: 20px 20px 0 0;
}

/** Couleur du container du section expandable */
.ion-color-sections-container {
	--ion-color-base: var(--ion-color-sections-container, var(--ion-color-primary));
	--ion-color-base-rgb: var(--ion-color-sections-container-rgb, var(--ion-color-primary-rgb));
	--ion-color-contrast: var(--ion-color-sections-container-contrast, var(--ion-color-primary-contrast));
	--ion-color-contrast-rgb: var(--ion-color-sections-container-contrast-rgb, var(--ion-color-primary-contrast-rgb));
	--ion-color-shade: var(--ion-color-sections-container-shade, var(--ion-color-primary-shade));
	--ion-color-tint: var(--ion-color-sections-container-tint, var(--ion-color-primary-tint));
}

/** Redéfinition de la couleur, pour le bouton sélectionné. */
.ion-color-slidebox-button.slidebox-active-tab {
	--ion-color-base: var(--ion-color-slidebox-active-button);
	--box-shadow: var(--idl-slidebox-shadow-active);
}

.ion-color-calao-log-action {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-calao-log-error {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

/** Couleur du rdv express perso */
.ion-color-purple {
	--ion-color-base: var(--ion-color-purple);
	--ion-color-base-rgb: var(--ion-color-purple-rgb);
	--ion-color-contrast: var(--ion-color-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-purple-shade);
	--ion-color-tint: var(--ion-color-purple-tint);
}

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/

:root {

	// Classes pour le SIDE MENU.
	.expandable-separation {
		margin-top: 1px;
	}

	.side-menu-with-header {
		padding-top: 0;
    overflow: auto;
    height: calc(100% - 70px);
	}

	.side-menu-without-header {
		padding-top: 0;
    overflow: auto;
    height: calc(100% - 70px);
	}

	// Pour la Gallery.
	.licamera-button {
		text-transform: none;
	}

	.file-picker-button {
		text-transform: none;
	}

	--custom-size-24px: 1.5rem;
	--custom-size-16px: 1rem;
	--custom-size-14px: 0.875rem;
	--custom-size-12px: 0.75rem;
	--custom-size-10px: 0.625rem;
}

@font-face {
	font-family: "Be Vietnam";
	font-style: normal;
	font-weight: 500;
	src: url("../assets/fonts/be-vietnam-regular.ttf");
}

@font-face {
	font-family: "Be Vietnam Light";
	font-style: normal;
	font-weight: 300;
	src: url("../assets/fonts/be-vietnam-light.ttf");
}

@font-face {
	font-family: "Be Vietnam Bold";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/be-vietnam-bold.ttf");
}

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

// App Variables globales à toutes les plateformes

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here
.ios {}

// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here
.md {}

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// @import "ionic.theme.default";
@import "ionic.theme.calao";
// @import "ionic.theme.dark";

// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/

// @import "ionicons";
// @import "style";

// Fonts
// --------------------------------------------------

@import "../assets/fonts/roboto.scss";
@import "../assets/fonts/noto-sans.scss";
