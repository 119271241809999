// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@angular/material/theming";
@import "material-icons/iconfont/material-icons.css";

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
	color: var(--ion-color-primary, lightgrey);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background: var(--ion-color-primary, lightgrey);
}

.mat-badge-content {
	color: #fff;
	background: var(--ion-color-primary, lightgrey);
	z-index: 1;
}

.user-contact .mat-badge-content {
	border-color: var(--ion-color-tertiary) !important;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
	color: var(--ion-color-primary, lightgrey);
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
	background-color: var(--ion-color-primary, lightgrey);
	color: #fff;
}

.mat-datepicker-toggle-active {
	color: var(--ion-color-primary, lightgrey);
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: var(--ion-color-primary, lightgrey);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
	color: var(--ion-color-primary, lightgrey);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: var(--ion-color-primary, lightgrey);
}

.mat-icon.mat-primary {
	color: var(--ion-color-primary, lightgrey);
}

.mat-input-element {
	caret-color: var(--ion-color-primary, lightgrey);
}

.mat-progress-bar-fill::after {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-progress-spinner circle,
.mat-spinner circle {
	stroke: var(--ion-color-primary, lightgrey);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
	border-color: var(--ion-color-primary, lightgrey);
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
	color: var(--ion-color-primary, lightgrey);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--ion-color-primary, lightgrey) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: var(--ion-color-primary, lightgrey);
	color: #fff;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-form-field-infix {
	padding: 0;
	border-top: 0.4em solid transparent;
}

.mat-form-field-label-wrapper {
	top: -0.5em;
	padding-top: 0.4em;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
	top: 1.09375em;
	margin-top: -0.5em;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 1em 0 1em 0;
	font-family: "Be Vietnam";
}

.mat-form-field-appearance-outline .mat-form-field-label {
	top: 1.8em;
	margin-top: -0.25em;
	font-family: "Be Vietnam";
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 0.5em;
}

/* Date range picker */
.calao-date-range-picker {
	.mat-form-field-wrapper {
		padding-bottom: 1.25em !important;
	}

	.mat-form-field-infix {
		padding: 0;
		margin: 0;
		border: 0;

		input {
			height: auto;
			font-size: 14px;
		}
	}
}

.txt-right {
	margin-left: auto;
	margin-right: 0;
}

.mat-datepicker-popup {
	position: unset;
	margin: auto;
	top: unset;
	left: unset;
}

ion-toolbar.navbar {
	.sc-ion-buttons-md-h {
		margin-top: 0;
	}

	ion-title.md {
		margin-top: 0;
	}

	.buttons-last-slot ion-button {
		height: 48px !important;
		width: 48px !important;
	}

	.sc-ion-buttons-md-s .button-has-icon-only.button-clear {
		--padding-start: 5px;
		--padding-end: 5px;
	}
}

.z-index-max {
	z-index: 999999 !important;
}

.disabled {
	opacity: 0.3;
	pointer-events: none;
	cursor: default;
}

a {
	color: var(--ion-color-osapp-element);
}

ion-content {
	--keyboard-offset: 0 !important;
}

/*Applique globalement à l'appli un fond noir transparent sur l'overlay des datepicker*/
div.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
	background-color: var(--Noir20);
}

@media (max-width: 768px) {
	.cdk-overlay-container div.cdk-overlay-pane.mat-datepicker-popup{
		left: calc(50% - 150px);
	}
}

// @import '@osapp/src/theme/material.scss'; TODO voir comment importer un ce fichier